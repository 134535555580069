.hero-image {
    object-fit: cover;
    width: 100%;
    height: 80%;
    position: fixed;
    z-index: -1;
}

.hero-container {
    background: url('./images/img-home.png') center center/cover no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
    object-fit: contain;
}

.hero-container > h1 {
    color: #ffffff;
    font-size: 100px;
    margin-top: -100px;
}


.text {
    margin-top: 8px;
    color: #ffffff;
    font-size: 30px;
    /* font-weight: bold; */
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.hero-btns {
    margin-top: 32px;
}

.hero-btns .btn {
    margin: 6px;
}

.fa-address-book {
    margin-left: 10px;
}

@media screen and (max-width: 960px) {
    .hero-container > h1 {
        font-size: 70px;
        margin-top: -150px;
    }
}

@media screen and (max-width: 769px) {
    .hero-container > h1 {
        font-size: 50px;
        margin-top: -100px;
    }

    .text {
        font-size: 20px;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .btn {
        width: 100%;
    }
}