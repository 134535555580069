#Alex{
    animation: Alex-fill 0.5s ease forwards 3.4s;
}

#Alex path:nth-child(1){
    stroke-dasharray: 140.48065185546875;
    stroke-dashoffset: 140.48065185546875;
    animation: line-anim 2s ease forwards;
}
#Alex path:nth-child(2){
    stroke-dasharray: 106.400390625;
    stroke-dashoffset:106.400390625;
    animation: line-anim 2s ease forwards 0.3s;
}
#Alex path:nth-child(3){
    stroke-dasharray: 122.20001220703125;
    stroke-dashoffset: 122.20001220703125;
    animation: line-anim 2s ease forwards 0.6s;
}
#Alex path:nth-child(4){
    stroke-dasharray: 121.5062255859375;
    stroke-dashoffset: 121.5062255859375;
    animation: line-anim 2s ease forwards 0.9s;
}
#Alex path:nth-child(5){
    stroke-dasharray: 120.60071563720703;
    stroke-dashoffset: 120.60071563720703;
    animation: line-anim 2s ease forwards 1.2s;
}
#Alex path:nth-child(6){
    stroke-dasharray: 342.9974670410156;
    stroke-dashoffset: 342.9974670410156;
    animation: line-anim 2s ease forwards 1.5s;
}
#Alex path:nth-child(7){
    stroke-dasharray: 153.1617736816406;
    stroke-dashoffset: 153.1617736816406;
    animation: line-anim 2s ease forwards 1.8s;
}
#Alex path:nth-child(8){
    stroke-dasharray: 171.1999816894531;
    stroke-dashoffset: 171.1999816894531;
    animation: line-anim 2s ease forwards 2.1s;
}


@keyframes line-anim{
    to{
        stroke-dashoffset: 0;
    }
}
@keyframes Alex-fill{
    from{
        fill: transparent;
    }
    to{
        fill: white;
    }
}