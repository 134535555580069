* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
}

.fade-appear,
.fade-enter {
  opacity: 0;
  transform: scale(1.1);
}
.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 250ms, transform 250ms;
}

.fade-exit {
  opacity: 1;
  transform: scale(1);
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 250ms, transform 250ms;
}

.Home,
.About,
.Project {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.About {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.Project {
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
