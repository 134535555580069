.footer-container {
    background-color: #242424;
    padding: 1rem 0 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-sub {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 24px;
    color: #fff;
}

.footer-sub > p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande','Lucida Sans', Arial, sans-serif;
}

.footer-heading {
    margin-bottom: 10px;
    font-size: 24px;
}

.footer-text {
    font-size: 20px;
}

/* Social Icons */
.social-media {
    max-width: 1000px;
    width: 100%;
}

.social-media-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 10px auto 0 auto;
}

.social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
}

.social-icon-link {
    color: #fff;
    font-size: 35px;
    transition: all 0.2s linear;
}

.social-icon-link:hover {
    transform: scale(2);
}

.social-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.website-rights {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    margin-top: 10px;
}

.rocket-to-top {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    margin-top: 10px;
}

.rocket {
    width: 35px;
    transition: all 0.2s linear;
}

.rocket:hover {
    cursor: pointer;
    width: 35px;
    transform: scale(1.5);
}

@media screen and (max-width: 820px) {
    .social-media-wrap {
        flex-direction: column;
    }
}