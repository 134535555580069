.cards {
  padding: 4rem;
  background: #fff;
}

.projects {
  padding: 2rem;
  background: #fff;
}

h1 {
  text-align: center;
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.cards__items {
  margin-bottom: 24px;
}

.projects__items {
  margin-bottom: 4rem;
}

.cards__item {
  display: flex;
  flex: 1;
  margin: 0 2rem;
  border-radius: 10px;
  transition: all 0.2s linear;
  width: 25rem;
}

.cards__project__item {
  display: flex;
  flex: 1;
  margin: 0 2rem;
  border-radius: 10px;
  transition: all 0.3s linear;
}

.cards__item:hover {
  transform: scale(1.1);
}

.cards__project__item:hover {
  transform: scale(1.1);
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  object-fit: cover;
}

.projects__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 70%;
  overflow: hidden;
  object-fit: scale-down;
  /* background-color: #242424; */
}

.cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: #1f98f4;
  box-sizing: border-box;
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
}

.projects__item__img {
  position: absolute;
  margin-top: 10px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: scale-down;
}

.cards__item__info {
  padding: 20px 30px 10px;
}

.projects__item__info {
  padding: 0 20px 10px 20px;
}

.cards__item__text {
  color: #252e48;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 10px;
}

.projects__title {
  color: #252e48;
  font-size: 20px;
  margin-bottom: 10px;
}

.projects__item__text {
  color: #252e48;
  white-space: pre-wrap;
}

.projects-links {
  display: flex;
  justify-content: flex-end;
  padding: 0 20px 10px 20px;
}

.projects-icon {
  -webkit-text-stroke: 1px #000;
  -webkit-text-fill-color: #fff;
  margin-right: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 30px;
}

.projects-icon:hover {
  -webkit-text-fill-color: #242424;
}

.skill__info {
  padding: 0 20px 10px 20px;
  margin-top: 10px;
}

.skill {
  box-sizing: border-box;
  margin-right: 8px;
  margin-bottom: 10px;
  color: #000;
  display: inline-block;
  user-select: none;
  cursor: default;
  padding: 0px 6px;
  border-radius: 2px;
  background: none;
}

.react {
  background-color: #61dbfb;
}

.node__js {
  background-color: #68a063;
}

.js {
  background-color: #f0db4f;
}

.B {
  background-color: #7e5bb3;
}

.html {
  background-color: #f06529;
}

.mt {
  background-color: #ef9a9a;
}

.css {
  background-color: #336cf0;
}

.seq {
  background-color: #144fd8;
}

.pg {
  background-color: #558fbe;
}

.aws {
  background-color: #efbd69;
}

.hk {
  background-color: #6762a6;
}

.dk{
  background-color: #0db7ed;
}

.gcp{
  background: linear-gradient(110deg, #4285f4 25%, #ea4335 25% 50%, #fbbc05 50% 75%, #34a853 75%);
}

.fa-external-link-alt {
  font-size: 27px;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 85%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
  }
}
